<template>
  <li class="">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div v-if="isExport" class="mr-3">
          <input
              v-if="node.childs.length > 0 || node.shops.length > 0"
              class="form-check-input"
              type="checkbox"
              :id="(parent) ? parent.id : node.id"
              @change="selectableElem($event, node)"
              :value="node.id"
              :checked="isDeepChecked"
          >
        </div>

        <b-icon
            v-if="node.childs.length > 0"
            :icon="(isOpen) ? 'folder2-open' : 'folder2'"
            font-scale="1.5"
            :variant="(isOpen) ? 'primary' : 'info'"
            class="icon-folder mr-2"
            v-on:click="handleFolderToggle()"
        ></b-icon>
        <b-icon
            v-else-if="node.shops.length > 0"
            icon="shop"
            font-scale="1.5"
            variant="info"
            class="mr-2"
        ></b-icon>
        <b-iconstack v-else font-scale="1.5" class="mr-2">
          <b-icon stacked icon="shop" variant="secondary" scale="0.75"></b-icon>
          <b-icon stacked icon="slash-circle" variant="danger"></b-icon>
        </b-iconstack>
        <p :style="{color: node.color}">
          {{ node.name }}
        </p>
      </div>

      <div v-if="!isExport">
        <b-icon
            class="mr-3 ml-3"
            icon="pencil-square"
            variant="primary"
            v-on:click="handleClickActionForm('edit', node.id)"
        ></b-icon>
      </div>
    </div>

    <ul class="" v-if="node.childs && node.childs.length && isOpen">
      <div v-if="isExport">
        <node
            v-for="(child, index) in node.childs"
            :node="child"
            :key="index"
            :parent="node"
            :is-export="isExport"
            :is-all-open="isAllOpen"
            :is-all-checked="isAllChecked"
            :is-checked="isDeepChecked"
            :is-parent-checked="isParentIsChecked"
            :event-selectable-cat="eventSelectableCat"
            @selectCat="eventSelectableCat"
        ></node>
      </div>
      <div v-else>
        <node
            v-for="(child, index) in node.childs"
            :node="child"
            :key="index"
            :is-export="isExport"
            :is-all-open="isAllOpen"
            :handle-click-action-form="handleClickActionForm"
            :event-selectable-cat="eventSelectableCat"
            @selectCat="eventSelectableCat"
        ></node>
      </div>
    </ul>
  </li>
</template>

<script>

export default {
  name: "node",
  data() {
    return {
      isOpen: false,
      isParentIsChecked: false,
      isDeepChecked: this.isChecked
    }
  },
  props: {
    node: Object,
    parent: Object,
    handleClickActionForm: Function,
    eventSelectableCat: Function,
    isExport: Boolean,
    isAllOpen: Boolean,
    isAllChecked: Boolean,
    isChecked: Boolean,
    isParentChecked: Boolean
  },
  watch: {
    isAllOpen: function(newValue)
    {
      this.isOpen = newValue
    },
    isAllChecked: function(newValue)
    {
      this.isDeepChecked = newValue
    },
    deep: true
  },
  mounted () {
    // Expand all
    if(this.isAllOpen !== null)
    {
      this.isOpen = this.isAllOpen
    }
    // Handle is open is parent checked
    if(this.isDeepChecked)
    {
      this.isOpen = this.isDeepChecked
    }
  },
  methods: {
    /**
     * Emit selectable elem from parent
     */
    selectableElem(event, cat) {
      let model = {
        checked: event.target.checked,
        id: cat.id,
        parent: (this.parent) ? this.parent.id : null
      }

      // Open folder on checked
      this.isOpen = !!model.checked

      // First parent
      if(this.parent || this.parent == null)
      {
        this.isParentIsChecked = model.checked
        this.isDeepChecked = model.checked
      }

      this.$emit('selectCat', model)
    },
    /**
     * Handle toggle folder on click
     */
    handleFolderToggle()
    {
      this.isOpen = !this.isOpen
    },
  }
};
</script>

<style scoped>

.icon-folder {
  cursor: pointer;
}

ul li {
  list-style-type: none;
}

</style>
